import React, { Component } from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage } from '../../../util/reactIntl';
import { lazyLoadWithDimensions } from '../../../util/uiHelpers';

import { H2, H3, NamedLink } from '../../../components';
import { data } from './config';
import css from './SectionFilteredSearches.module.css';



// Thumbnail image for the search "card"
class ThumbnailImage extends Component {
  render() {
    const { alt, ...rest } = this.props;
    return <img alt={alt} {...rest} />;
  }
}
// Load the image only if it's close to viewport (user has scrolled the page enough).
const LazyImage = lazyLoadWithDimensions(ThumbnailImage);

// Create a "card" that contains a link to filtered search on SearchPage.
const FilterLink = props => {
  const { link, title, icon, description, buttonText } = props;
  const url = typeof window !== 'undefined' ? new window.URL(link) : new global.URL(link);
  const searchQuery = url.search;
  return (
    <div className={css.linksHolder}>
      <div className={css.iconHolder}>
        <img src={icon} alt={title} />
      </div>

      <div className={css.linkDataHolder}>
        <H3 className={css.linkTitle}>{title}</H3>
        <p className={css.linkDescription}>{description}</p>
        <NamedLink name="SearchPage" to={{ search: searchQuery }} className={css.linkButton}>
          {buttonText}
        </NamedLink>
      </div>

    </div>
  );
};

// Component that shows full-width section on LandingPage.
// Inside it shows 3 "cards" that link to SearchPage with specific filters applied.
const SectionFilteredSearches = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  const renderFilterLinks = (data) => {
    return data.map(((item, index) => {
      return <FilterLink
        key={index}
        title={item.title}
        description={item.description}
        icon={item.icon}
        link={item.link}
        buttonText={item.buttonText}
      />
    }))
  }

  // FilterLink props:
  // - "name" is a string that defines what kind of search the link is going to make
  // - "image" is imported from images directory and you can update it by changing the file
  // - "link" should be copy-pasted URL from search page.
  //    The domain doesn't matter, but search query does. (I.e. "?pub_brand=nike")
  return (
    <div className={classes}>
      <H2 className={css.title}>
        <FormattedMessage id="SectionFilteredSearches.title" />
      </H2>
      <div className={css.filteredSearches}>
        {renderFilterLinks(data)}
      </div>
    </div>
  );
};

SectionFilteredSearches.defaultProps = { rootClassName: null, className: null };

SectionFilteredSearches.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionFilteredSearches;
