import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage } from '../../../util/reactIntl';

import { H3, H4, NamedLink } from '../../../components';
import mobileImg from './images/join-bg-mobile.png';
import css from './SectionJoin.module.css';

const SectionJoin = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      <div className={css.title}>
        <H3 className={css.mainTitle}>
          <FormattedMessage id="SectionJoin.title" />
        </H3>
      </div>

      <div className={css.holder}>
        <div className={css.imageHolder}>
          <img className={css.image} src={mobileImg} alt='Join section' />
        </div>
        <div className={css.holderLeft}>
          <H4 className={css.title}>
            For Therapists
          </H4>
          <p className={css.description}>Sign up to access a vast library of resources tailored to your needs.</p>
          <NamedLink className={css.buttonLeft} name="NewListingPage">
            Get started
          </NamedLink>
        </div>
        <div className={css.holderRight}>
          <H4 className={css.title}>
            For Sellers
          </H4>
          <p className={css.description}>Join our community of experts and start sharing your valuable resources with fellow professionals.</p>
          <NamedLink className={css.buttonRight} name="NewListingPage">
            Become a seller
          </NamedLink>
        </div>
      </div>

    </div>
  );
};

SectionJoin.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionJoin.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionJoin;
