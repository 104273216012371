import React from 'react';
import { FormattedMessage } from '../../../util/reactIntl';

import sectionImage from "./images/img.png";
import { NamedLink } from "../../../components";
import IconCheckMark from './images/IconCheckMark';

import css from './SectionWhy.module.css';

const SectionWhy = () => {

  return (
    <div className={css.root}>
      <h2 className={css.rootTitle}>
        <FormattedMessage id="SectionWhy.title" />
      </h2>

      <div className={css.adminInfo}>
        <div className={css.imgHolder}>
          <img className={css.image} src={sectionImage} alt="section image" />
        </div>

        <ul className={css.list}>
          <li className={css.listItem}>
            <div className={css.listItemTitle}>
              <span className={css.listIcon} />
              <FormattedMessage id="SectionWhy.itemTitle1" />
            </div>
            <p className={css.listItemDescription}>
              <FormattedMessage id="SectionWhy.itemDescription1" />
            </p>
          </li>
          <li className={css.listItem}>
            <div className={css.listItemTitle}>
              <span className={css.listIcon} />
              <FormattedMessage id="SectionWhy.itemTitle2" />
            </div>
            <p className={css.listItemDescription}>
              <FormattedMessage id="SectionWhy.itemDescription2" />
            </p>
          </li>
          <li className={css.listItem}>
            <div className={css.listItemTitle}>
              <span className={css.listIcon} />
              <FormattedMessage id="SectionWhy.itemTitle3" />
            </div>
            <p className={css.listItemDescription}>
              <FormattedMessage id="SectionWhy.itemDescription3" />
            </p>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SectionWhy;
