import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from '../../LandingPage.module.css';

const IconCard = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg className={classes} width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="40" height="40" rx="8" fill="#FFAEAB" />
      <path d="M31.333 12.9166L17.1663 27.0833L10.083 20" stroke="#FDFDFD" strokeWidth="2"
            />
    </svg>
  );
};

IconCard.defaultProps = {
  className: null,
  rootClassName: null,
};

IconCard.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconCard;
