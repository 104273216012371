// Update images by saving images to src/LandingPage/SeactionFilteredSearches/images directory.
// If those images have been saved with the same name, no need to make changes to these imports.
import productsImg from './images/products.svg';
import trainingImg from './images/training.svg';
import consultantImg from './images/consultant.svg';
import connectionsImg from './images/connections.svg';

export const data = [
  {
    icon: productsImg,
    title: 'Resources',
    description:
      'Browse downloadable products. From clinical worksheets to administrative tools, purchase resources directly from other therapists.',
    buttonText: 'Shop Products',
    link: 'http://localhost:3000/s?pub_brand=products',
  },
  {
    icon: trainingImg,
    title: 'Training',
    description: 'Learn from the best. Browse pre-recorded and upcoming live trainings from various specialists to continue learning and growing.',
    buttonText: 'Browse Trainings',
    link: 'http://localhost:3000/s?pub_brand=training',
  },
  {
    icon: consultantImg,
    title: 'Consultation',
    description: 'Get the answers to your questions from a professional. Whether you are an organization or clinician, you can find quality consultation with a specialist in their field.',
    buttonText: 'Search Consultant',
    link: 'http://localhost:3000/s?pub_brand=consultans',
  },
  {
    icon: connectionsImg,
    title: 'Community',
    description: 'No one should feel alone. Explore what your community has to offer. Discover local resources and connect with other professionals in your area.',
    buttonText: 'Find Connections',
    link: 'http://localhost:3000/s?pub_brand=connections',
  },
];
