import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/ui.duck';
import css from './LandingPage.module.css';
import { useConfiguration } from '../../context/configurationContext';
import classNames from 'classnames';

// Components
import { Page, LayoutSingleColumn } from '../../components';

import TopbarContainer from '../../containers/TopbarContainer/TopbarContainer';
import FooterContainer from '../FooterContainer/FooterContainer';

// Sections
import SectionHero from './SectionHero/SectionHero';
import SectionFilteredSearches from './SectionFilteredSearches/SectionFilteredSearches';
import SectionWhy from './SectionWhy/SectionWhy';
import SectionFeatures from './SectionFeatures/SectionFeatures';
import SectionExperts from './SectionExperts/SectionExperts';
import SectionJoin from './SectionJoin/SectionJoin';

// images
import ava from './SectionFeatures/images/ava.png';
import featuresImage from './SectionFeatures/images/image.png';
import expertsImage from './SectionExperts/images/image.png';

const featuresData = [
  {
    title: 'Product Title',
    img: featuresImage,
    avatarImg: ava,
    author: 'Seller Name',
    category: 'Adult Therapy',
    price: '$380',
  },
  {
    title: 'Product Title',
    img: featuresImage,
    avatarImg: ava,
    author: 'Seller Name',
    category: 'Adult Therapy',
    price: '$380',
  },
  {
    title: 'Product Title',
    img: featuresImage,
    avatarImg: ava,
    author: 'Seller Name',
    category: 'Adult Therapy',
    price: '$380',
  },
  {
    title: 'Product Title',
    img: featuresImage,
    avatarImg: ava,
    author: 'Seller Name',
    category: 'Adult Therapy',
    price: '$380',
  },
  {
    title: 'Product Title',
    img: featuresImage,
    avatarImg: ava,
    author: 'Seller Name',
    category: 'Adult Therapy',
    price: '$380',
  },
  {
    title: 'Product Title',
    img: featuresImage,
    avatarImg: ava,
    author: 'Seller Name',
    category: 'Adult Therapy',
    price: '$380',
  },
  {
    title: 'Product Title',
    img: featuresImage,
    avatarImg: ava,
    author: 'Seller Name',
    category: 'Adult Therapy',
    price: '$380',
  },
  {
    title: 'Product Title',
    img: featuresImage,
    avatarImg: ava,
    author: 'Seller Name',
    category: 'Adult Therapy',
    price: '$380',
  },
];

const expertsData = [
  {
    title: 'Seller Name',
    img: expertsImage,
    description:
      'Lorem ipsum orem ipsum orem ipsum orem ipsum orem ipsum orem ipsum orem ipsum orem ipsum',
    experience: '5 years',
    license: 'License number',
  },
  {
    title: 'Seller Name',
    img: expertsImage,
    description:
      'Lorem ipsum orem ipsum orem ipsum orem ipsum orem ipsum orem ipsum orem ipsum orem ipsum',
    experience: '5 years',
    license: 'License number',
  },
  {
    title: 'Seller Name',
    img: expertsImage,
    description:
      'Lorem ipsum orem ipsum orem ipsum orem ipsum orem ipsum orem ipsum orem ipsum orem ipsum',
    experience: '5 years',
    license: 'License number',
  },
  {
    title: 'Seller Name',
    img: expertsImage,
    description:
      'Lorem ipsum orem ipsum orem ipsum orem ipsum orem ipsum orem ipsum orem ipsum orem ipsum',
    experience: '5 years',
    license: 'License number',
  },
  {
    title: 'Seller Name',
    img: expertsImage,
    description:
      'Lorem ipsum orem ipsum orem ipsum orem ipsum orem ipsum orem ipsum orem ipsum orem ipsum',
    experience: '5 years',
    license: 'License number',
  },
  {
    title: 'Seller Name',
    img: expertsImage,
    description:
      'Lorem ipsum orem ipsum orem ipsum orem ipsum orem ipsum orem ipsum orem ipsum orem ipsum',
    experience: '5 years',
    license: 'License number',
  },
  {
    title: 'Seller Name',
    img: expertsImage,
    description:
      'Lorem ipsum orem ipsum orem ipsum orem ipsum orem ipsum orem ipsum orem ipsum orem ipsum',
    experience: '5 years',
    license: 'License number',
  },
  {
    title: 'Seller Name',
    img: expertsImage,
    description:
      'Lorem ipsum orem ipsum orem ipsum orem ipsum orem ipsum orem ipsum orem ipsum orem ipsum',
    experience: '5 years',
    license: 'License number',
  },
];

export const LandingPageComponent = props => {
  const { history, intl, location, scrollingDisabled } = props;

  const config = useConfiguration();

  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  const marketplaceName = config.marketplaceName;
  const schemaTitle = intl.formatMessage({ id: 'LandingPage.schemaTitle' }, { marketplaceName });
  const schemaDescription = intl.formatMessage({ id: 'LandingPage.schemaDescription' });

  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
      }}
    >
      <LayoutSingleColumn topbar={<TopbarContainer />} footer={<FooterContainer />}>
        <div className={css.heroContainer}>
          <SectionHero
            rootClassName={css.heroRoot}
            className={css.hero}
            history={history}
            location={location}
          />
        </div>
        <ul className={css.sections}>
          <li className={css.section}>
            <div className={css.sectionContentFirstChild}>
              <SectionFilteredSearches />
            </div>
          </li>
          <li className={classNames(css.section, css.coloredSection)}>
            <div className={css.sectionContent}>
              <SectionWhy />
            </div>
          </li>
          <li className={css.section}>
            <div className={css.sectionContent}>
              <SectionFeatures data={featuresData} />
            </div>
          </li>
          <li className={classNames(css.section, css.coloredSection)}>
            <div className={css.sectionContent}>
              <SectionExperts data={expertsData} />
            </div>
          </li>
          <li className={css.section}>
            <div className={classNames(css.sectionContent, css.sectionContentNoMargin)}>
              <SectionJoin />
            </div>
          </li>
        </ul>
      </LayoutSingleColumn>
    </Page>
  );
};

const { bool, object } = PropTypes;

LandingPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  return {
    scrollingDisabled: isScrollingDisabled(state),
  };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(
  withRouter,
  connect(mapStateToProps),
  injectIntl
)(LandingPageComponent);

export default LandingPage;
