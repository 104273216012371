import React, { Component } from 'react';
import { lazyLoadWithDimensions } from '../../../util/uiHelpers';
import AliceCarousel from 'react-alice-carousel';

// Copied directly from
// `/node_modules/react-alice-carousel/lib/alice-carousel.css`. The
// copied file is left unedited, and all the overrides are defined in
// the component CSS file below.
import '../../../styles/react-alice-carousel.css';
import { FormattedMessage } from "../../../util/reactIntl";
import { H3, NamedLink, ResponsiveImage } from '../../../components';


import css from './SectionExperts.module.css';
import classNames from 'classnames';
import { createSlug } from '../../../util/urlHelpers';

class LocationImage extends Component {
  render() {
    const { alt, ...rest } = this.props;
    return <div className={css.imageContainer}><ResponsiveImage className={css.image} {...this.props} /></div>
  }
}

const LazyImage = lazyLoadWithDimensions(LocationImage);
const IMAGE_VARIANTS = [
  'square-xsmall',
  'square-xsmall2x',
  'square-small',
  'square-small2x',
];


const SectionExperts = (props) => {
  const { data } = props;

  const items = () => {
    if (!data) {
      return '';
    }
    return data.map((item, index) => {
      const { title, img, description, experience, license } = item;

      return (
        <div key={index} className={css.itemsWrapper} >

          <div className={css.imgHolder}>
            <img className={css.image} src={img} alt={title} />
          </div>
          <div className={css.dataHolder}>
            <H3 className={css.title}>{title}</H3>
            <p className={css.description}>{description}</p>
            <div className={css.experienceHolder}>
              <span className={css.experienceLabel}>Experience:</span>
              <span className={css.experienceName}>{experience}</span>
            </div>
            <div className={css.licenseHolder}>
              <span className={css.licenseLabel}>License in:</span>
              <span className={css.license}>{license}</span>
            </div>

            <div className={css.buttonHolder}>
              <NamedLink className={css.button} name="SearchPage" >
                View profile
              </NamedLink>
            </div>
          </div>

        </div>
      );
    });
  };

  return <div className={css.itemsCarouselHolder}>
    <h2 className={css.rootTitle}>
      <FormattedMessage id="SectionExperts.title" />
    </h2>
    <p className={css.rootDescription}>
      <FormattedMessage id="SectionExperts.subtitle" />
    </p>
    <AliceCarousel
      mouseTrackingEnabled
      items={items()}
      disableDotsControls={true}
      // infinite={true}
      responsive={{
        0: {
          items: 1,
        },
        550: {
          items: 2,
        },
        1024: {
          items: 4,
        }
      }}
    />
  </div>;
};

export default SectionExperts;
